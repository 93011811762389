import React from "react"
import { graphql } from "gatsby"

import { Utility } from "@hurleymc/components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from "../components/page-builder"

const Testimonial = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]

  const image =
    entry.image && entry.image.length > 0 && entry.image[0].lg
      ? entry.image[0].lg
      : null

  return (
    <Layout>
      <SEO title={entry.title} image={image} />
      <Utility
        header={entry.header}
        html={entry.quote}
        identifier="Testimonial"
        image={entry.image}
      />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query Testimonial($id: [Craft_QueryParameter]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        uri
        ... on Craft_testimonial_testimonial_Entry {
          header
          image {
            ... on Craft_AssetInterface {
              title
              url
              xs: url(transform: "utilitySplashXs")
              sm: url(transform: "utilitySplashSm")
              md: url(transform: "utilitySplashMd")
              lg: url(transform: "utilitySplashLg")
              xl: url(transform: "utilitySplashXl")
            }
          }
          pageBuilder {
            ...PageBuilder
          }
          quote
        }
      }
    }
  }
`

export default Testimonial
